import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Services = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const sessions = data.allService.nodes
    
    if (sessions.length === 0){
        return (
            <Layout>
                <p>Please check back later for listings</p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
        
            <div className="session">
                <div className="session-tab">
                    <h4>{sessions[0].tabHeader}</h4>
                </div>
                <div className="session-body">
                    <div className="session-image">
                        <StaticImage
                            layout="fixed"
                            formats={["auto", "webp", "avif"]}
                            src="../images/full-meditation-squiggle.png"
                            width={250}
                            height={250}
                            quality={95}
                            alt="energy wellness"
                        />
                    </div>
                    <div className="session-description">
                        <h4 className="session-title">{sessions[0].title}</h4>
                        <h5 className="session-subtitle">{sessions[0].subtitle}</h5>
                        <p><em>{sessions[0].interest}</em></p>
                        <p>{sessions[0].description}</p>
                        <button className="session-button"><a href={sessions[0].bookingUrl} target="_blank"  rel="noopener noreferrer">Book Session</a></button>
                    </div>
                </div>
            </div>
            <div className="session">
                <div className="session-tab">
                    <h4>{sessions[1].tabHeader}</h4>
                </div>
                <div className="session-body">
                    <div className="session-image">
                        <StaticImage
                            layout="fixed"
                            formats={["auto", "webp", "avif"]}
                            src="../images/reiki-meditation-squiggle.png"
                            width={250}
                            height={250}
                            quality={95}
                            alt="reiki meditation"
                        />
                    </div>
                    <div className="session-description">
                        <h4 className="session-title">{sessions[1].title}</h4>
                        <h5 className="session-subtitle">{sessions[1].subtitle}</h5>
                        <p><em>{sessions[1].interest}</em></p>
                        <p>{sessions[1].description}</p>
                        <button className="session-button"><a href={sessions[1].bookingUrl} target="_blank"  rel="noopener noreferrer">Book Session</a></button>
                    </div>
                </div>
            </div>
            <div className="session">
                <div className="session-tab">
                    <h4>{sessions[2].tabHeader}</h4>
                </div>
                <div className="session-body">
                    <div className="session-image">
                        <StaticImage
                            layout="fixed"
                            formats={["auto", "webp", "avif"]}
                            src="../images/art-meditation-squiggle.png"
                            width={250}
                            height={250}
                            quality={95}
                            alt="art meditation"
                        />
                    </div>
                    <div className="session-description">
                        <h4 className="session-title">{sessions[2].title}</h4>
                        <h5 className="session-subtitle">{sessions[2].subtitle}</h5>
                        <p><em>{sessions[2].interest}</em></p>
                        <p>{sessions[2].description}</p>
                        <button className="session-button"><a href={sessions[2].bookingUrl} target="_blank"  rel="noopener noreferrer">Book Session</a></button>
                    </div>
                </div>
            </div>
            <div className="session">
                <div className="session-tab">
                    <h4>{sessions[3].tabHeader}</h4>
                </div>
                <div className="session-body">
                    <div className="session-image">
                        <StaticImage
                            layout="fixed"
                            formats={["auto", "webp", "avif"]}
                            src="../images/mediumship-squiggle.png"
                            width={250}
                            height={250}
                            quality={95}
                            alt="mediumship"
                        />
                    </div>
                    <div className="session-description">
                        <h4 className="session-title">{sessions[3].title}</h4>
                        <h5 className="session-subtitle">{sessions[3].subtitle}</h5>
                        <p><em>{sessions[3].interest}</em></p>
                        <p>{sessions[3].description}</p>
                        <button className="session-button"><a href={sessions[3].bookingUrl} target="_blank"  rel="noopener noreferrer">Book Session</a></button>
                    </div>
                </div>
            </div>
            <div className="session">
                <div className="session-tab">
                    <h4>{sessions[4].tabHeader}</h4>
                </div>
                <div className="session-body">
                    <div className="session-image">
                        <StaticImage
                            layout="fixed"
                            formats={["auto", "webp", "avif"]}
                            src="../images/intuition-coaching-squiggle.png"
                            width={250}
                            height={250}
                            quality={95}
                            alt="intuition coaching"
                        />
                    </div>
                    <div className="session-description">
                        <h4 className="session-title">{sessions[4].title}</h4>
                        <h5 className="session-subtitle">{sessions[4].subtitle}</h5>
                        <p><em>{sessions[4].interest}</em></p>
                        <p>{sessions[4].description}</p>
                        <button className="session-button"><a href={sessions[4].bookingUrl} target="_blank"  rel="noopener noreferrer">Book Session</a></button>
                    </div>
                </div>
            </div>
    
        </Layout>
    )
}

export default Services

export const Head = () => <Seo title="Zai Duck Wellness Services" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allService {
        nodes {
            bookingUrl
            description
            title
            subtitle
            tabHeader
            imageSrc
            interest
        }
    }
}`